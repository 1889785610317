import React from 'react';
import { Typography, Container, Box } from '@mui/material';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';

const NotFound = () => {
    return (
        <Container maxWidth="sm">
            <Helmet>
                <title>404 - Page Not Found | Media Utils</title>
                <meta name="description" content="The page you're looking for doesn't exist. Please check the URL or return to the homepage." />
            </Helmet>
            <Box sx={{ my: 4, textAlign: 'center' }}>
                <Typography variant="h2" component="h1" gutterBottom>
                    404 - Page Not Found
                </Typography>
                <Typography variant="body1" paragraph>
                    The page you're looking for doesn't exist. Please check the URL or return to the homepage.
                </Typography>
                <Link to="/" style={{ textDecoration: 'none' }}>
                    <Typography variant="button" color="primary">
                        Go to Homepage
                    </Typography>
                </Link>
            </Box>
        </Container>
    );
};

export default NotFound;