import React from 'react';
import { Button } from '@mui/material';
import DownloadIcon from '@mui/icons-material/Download';
import { API_URL } from "../../constants/global";
import { saveAs } from 'file-saver';
import JSZip from 'jszip';

const DownloadButton = ({ output, operation }) => {
    const handleDownloadFiles = async () => {
        if (output.length === 1) {
            // Handle single file download
            const fileUrl = `${API_URL}${output[Object.keys(output)[0]].imagePath}`;
            const fileName = output[Object.keys(output)[0]].imagePath.split('/').pop();
            const res = await fetch(fileUrl);
            const blob = await res.blob();
            saveAs(blob, fileName);
        } else if (output.length > 1) {
            // Handle multiple files download as a zip
            const zip = new JSZip();
            for (const image of output) {
                const fileUrl = `${API_URL}${image.imagePath}`;
                const fileName = image.imagePath.split('/').pop();
                const res = await fetch(fileUrl);
                const blob = await res.blob();
                zip.file(fileName, blob);
            }
            zip.generateAsync({ type: 'blob' }).then((content) => {
                saveAs(content, `media_utils_${operation.toLowerCase()}.zip`);
            });
        }
    };
    return (
        <Button variant="contained" color="primary" onClick={handleDownloadFiles}>
            <DownloadIcon />
            Download {operation} Images
        </Button>
    );
};

export default DownloadButton;
