import React from 'react';
import { Button } from '@mui/material';
import AddIcon from '@mui/icons-material/Add'; // Ensure this is imported correctly

const FileSelector = ({ filesLength, handleFileChange, allowedFileType }) => {
    return (
        <Button variant="contained" component="label" sx={{ mb: 2 }}>
            {filesLength === 0 ? "Select Images" : <AddIcon />}
            <input
                type="file"
                accept={allowedFileType}
                hidden
                multiple
                onChange={handleFileChange}
            />
        </Button>
    );
};

export default FileSelector;