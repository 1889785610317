import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { CssBaseline, Container, ThemeProvider, createTheme, Box } from '@mui/material';
import Navbar from './components/Navbar';
import Compress from './components/Compress';
import Convert from './components/Convert';
import Crop from './components/Crop';
import Rotate from './components/Rotate';
import Home from './components/Home';
import Footer from './components/Footer';
import PrivacyPolicy from './components/PrivacyPolicy';
import TermsOfService from './components/TermsOfService';
import ContactUs from './components/ContactUs';
import NotFound from './components/NotFound';

// import Watermark from './components/Watermark';
// import RemoveBackground from './components/RemoveBackground';

const theme = createTheme({
    palette: {
        primary: {
            main: '#1976d2',
        },
        secondary: {
            main: '#dc004e',
        },
    },
});

function App() {
    return (
        <ThemeProvider theme={theme}>
            <CssBaseline />
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    minHeight: '100vh',
                }}
            >
                <Router>
                    <Navbar />
                    <Container sx={{ flex: 1 }}>
                        <Routes>
                            <Route path="/" element={<Home />} />
                            <Route path="/compress-image" element={<Compress />} />
                            <Route path="/convert-image" element={<Convert />} />
                            <Route path="/crop-image" element={<Crop />} />
                            <Route path="/rotate-image" element={<Rotate />} />
                            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
                            <Route path="/terms-of-service" element={<TermsOfService />} />
                            <Route path="/contact-us" element={<ContactUs />} />
                            {/* <Route path="/watermark" element={<Watermark />} />
                            <Route path="/remove-background" element={<RemoveBackground />} /> */}
                            {/* Catch-all route for 404 */}
                            <Route path="*" element={<NotFound />} />
                        </Routes>
                    </Container>
                    <Footer />
                </Router>
            </Box>
        </ThemeProvider>
    );
}

export default App;
