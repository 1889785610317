// let razorpay_key;
let API_LINK;
let S3_LINK;

if (window.location.hostname === 'testweb.abc.com' || window.location.hostname === 'localhost') {
    // razorpay_key = process.env.REACT_APP_TEST_RAZORPAY_KEY;
    API_LINK = process.env.REACT_APP_TEST_API_LINK;
    S3_LINK = process.env.REACT_APP_S3_LINK_TEST;

} else {
    // razorpay_key = process.env.REACT_APP_RAZORPAY_KEY;
    API_LINK = process.env.REACT_APP_LIVE_API_LINK;
    S3_LINK = process.env.REACT_APP_S3_LINK_LIVE;
}

// export const razorpayKey = razorpay_key;
export const API_URL = API_LINK;
export const S3_URL = S3_LINK;
