import React, { useState } from 'react';
import { Button, Typography, Box } from '@mui/material';
import { Helmet } from 'react-helmet';
import apiService from '../services/apiService';
import LoadingScreen from './LoadingScreen';
import DownloadButton from './helper/DownloadButton'

const Rotate = () => {
    const [file, setFile] = useState(null);
    const [loading, setLoading] = useState(false);
    const [output, setOutput] = useState('');
    const [imagePreview, setImagePreview] = useState(null);
    const [rotationDegrees, setRotationDegrees] = useState(0);  // Degrees for preview

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        setRotationDegrees(0);
        setFile(file);
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setImagePreview(reader.result);
            };
            reader.readAsDataURL(file);
        }
    };

    const handleRotate = (degrees) => {
        setRotationDegrees(prevDegrees => (prevDegrees + degrees) % 360); // Update rotation degrees
    };

    // This could handle sending the image to the server if needed
    const submitRotation = async () => {
        // API call to server with `file` and `rotationDegrees`
        setLoading(true);
        const formData = new FormData();

        try {
            formData.append('image', file);
            formData.append('angle', rotationDegrees);

            const response = await apiService.rotateImage(formData);
            if (response.status === 200) {
                setOutput([response.data.rotatedImage]);
            } else {
                throw new Error(JSON.stringify({ status: response.status, body: await response.text() }))
            }
        } catch (error) {
            console.error('Error processing the image', error);
        } finally {
            setLoading(false);
        }
        // Reset rotation preview after submission
        // setRotationDegrees(0);
    };

    return (
        <Box sx={{ textAlign: 'center', mt: 4 }}>
            <Helmet>
                <title>Rotate Images - Free Online Image Compression | Media Utils</title>
                <meta name="description" content="Rotate your images to perfect their alignment or create unique compositions. Our tool supports multiple formats and provides instant high-quality results." />
            </Helmet>
            {loading ? (
                <LoadingScreen textData="Converting" />
            ) : (
                <>
                    {
                        output.length === 0 ? (<>
                            <Typography variant="h1" component="h1" gutterBottom sx={{ fontSize: '2.5rem', fontWeight: 'bold' }}>Rotate Image</Typography>
                            <Typography variant="h6" gutterBottom>Rotate JPG, PNG or GIF.</Typography>
                            <Button variant="contained" component="label" sx={{ mb: 2 }}>
                                Select Image
                                <input type="file" hidden onChange={handleFileChange} accept="image/*" />
                            </Button>
                            <br />
                            {imagePreview && (
                                <Box sx={{ mb: 2, maxWidth: 300 }}>
                                    <img src={imagePreview} alt="Preview" style={{ width: '100%', height: 'auto', transform: `rotate(${rotationDegrees}deg)` }} />
                                </Box>
                            )}
                            <Button variant="contained" color="primary" onClick={() => handleRotate(90)} sx={{ mr: 2 }}>
                                Rotate +90°
                            </Button>
                            <Button variant="contained" color="primary" onClick={() => handleRotate(-90)}>
                                Rotate -90°
                            </Button>
                            <br />
                            <Button variant="contained" color="primary" onClick={submitRotation} sx={{ mt: 2 }}>
                                Confirm Rotation
                            </Button>
                        </>
                        ) : (<>
                            <Box sx={{ mt: 4 }}>
                                <Typography variant="h5" gutterBottom>Your Image is Rotated!</Typography>
                                <DownloadButton output={output} operation={"Rotated"} />
                            </Box>
                        </>)
                    }
                </>
            )}

        </Box>
    )
};

export default Rotate;
