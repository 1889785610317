import React, { useState, useEffect } from 'react';
import { Box, Grid, Card, CardMedia, Typography } from '@mui/material';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { API_URL } from '../constants/global';
import DownloadButton from "./helper/DownloadButton"

const CompressionOutputPreview = ({ output }) => {
    const [totalOriginalSize, setTotalOriginalSize] = useState([]);
    const [totalCompressedSize, setTotalCompressedSize] = useState([]);
    const [totalPercentage, setTotalPercentage] = useState([]);

    const compressedImages = output.compressedImages;

    useEffect(() => {
        if (output && output.compressedImages) {
            setTotalOriginalSize(output.totalOriginalSize);
            setTotalCompressedSize(output.totalCompressedSize);
            setTotalPercentage(output.totalPercentage);
        }
    }, [output]);

    return (
        <Box sx={{ mt: 4 }}>
            <DownloadButton output={compressedImages} operation={"Compression"} />
            <Typography variant="h5" gutterBottom>Your Images are now {totalPercentage}% smaller!</Typography>
            <Typography variant="h5"><strong>{totalOriginalSize} <ArrowForwardIcon /> {totalCompressedSize}</strong> </Typography>

            <Grid container spacing={2}>
                {compressedImages.map((img, index) => (
                    <Grid item key={index}>
                        <Card sx={{ maxWidth: 150 }}>
                            <CardMedia
                                component="img"
                                height="100"
                                image={`${API_URL}${img.imagePath}`}
                                alt={`output ${index}`}
                            />
                        </Card>
                    </Grid>
                ))}
            </Grid>
        </Box>
    );
};

export default CompressionOutputPreview;
