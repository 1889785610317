import React from 'react';
import { Container, Typography, Box } from '@mui/material';
import { Helmet } from 'react-helmet';

const TermsOfService = () => {
    return (
        <Container sx={{ mt: 4 }}>
            <Helmet>
                <title>Terms of Service - Media Utils</title>
                <meta name="description" content="Terms of Service for Media Utils - Read our terms and conditions for using our services." />
            </Helmet>

            <Typography variant="h1" gutterBottom sx={{ fontSize: '3.5rem', fontWeight: 'bold' }}>Terms of Service for Media Utils</Typography>
            <Typography variant="body2" paragraph>Last Updated: September 05, 2024</Typography>

            <Box sx={{ textAlign: 'left', mb: 4 }}>
                <Typography paragraph>
                    Welcome to Media Utils ("I", "me", or "my"). Thank you for choosing to be part of our community. I am committed to protecting your personal information and your right to privacy. If you have any questions or concerns about my policy, or my practices with regards to your personal information, please contact me at https://mediautils.app/contact-us.
                </Typography>
                <Typography paragraph>
                    By using my website, https://mediautils.app, and my services, you agree to the terms and conditions contained in this Terms of Service Agreement.
                </Typography>

                <Typography variant="h4" gutterBottom>1. Agreement to Terms</Typography>
                <Typography paragraph>
                    You agree that by accessing the site and/or services, you have read, understood, and agree to be bound by all of these Terms of Service. If you do not agree with all of these terms, you are expressly prohibited from using the site and you must discontinue use immediately.
                </Typography>

                <Typography variant="h4" gutterBottom>2. Intellectual Property Rights</Typography>
                <Typography paragraph>
                    Unless otherwise indicated, the site and all content and other materials therein, including, without limitation, the logo and all designs, text, graphics, pictures, information, data, software, sound files, other files, and the selection and arrangement thereof are my proprietary property or are licensed to me and are protected by international copyright and trademark laws.
                </Typography>

                <Typography variant="h4" gutterBottom>3. User Representations</Typography>
                <Typography paragraph>
                    By using the Site, you represent and warrant that: (1) all registration information you submit will be true, accurate, current, and complete; (2) you will maintain the accuracy of such information and promptly update such registration information as necessary; (3) you have the legal capacity and you agree to comply with these Terms of Service; (4) you are not a minor in the jurisdiction in which you reside; (5) you will not access the site through automated or non-human means, whether through a bot, script, or otherwise; (6) you will not use the site for any illegal or unauthorized purpose; and (7) your use of the site will not violate any applicable law or regulation.
                </Typography>

                <Typography variant="h4" gutterBottom>4. User Registration</Typography>
                <Typography paragraph>
                    You may be required to register with the site. You agree to keep your password confidential and will be responsible for all use of your account and password. I reserve the right to remove, reclaim, or change a username you select if I determine, in my sole discretion, that such username is inappropriate, obscene, or otherwise objectionable.
                </Typography>

                <Typography variant="h4" gutterBottom>5. Prohibited Activities</Typography>
                <Typography paragraph>
                    You may not access or use the site for any purpose other than that for which I make the site available. The site may not be used in connection with any commercial endeavors except those that are specifically endorsed or approved by me.
                </Typography>

                <Typography variant="h4" gutterBottom>6. Modifications and Interruptions</Typography>
                <Typography paragraph>
                    I reserve the right to change, modify, or remove the contents of the site at any time or for any reason at my sole discretion without notice. I also reserve the right to modify or discontinue all or part of the services without notice at any time.
                </Typography>

                <Typography variant="h4" gutterBottom>7. Governing Law</Typography>
                <Typography paragraph>
                    These terms shall be governed by and defined following the laws of Maharashtra, India. I and yourself irrevocably consent that the courts of Maharashtra, India shall have exclusive jurisdiction to resolve any dispute which may arise in connection with these terms.
                </Typography>

                <Typography variant="h4" gutterBottom>8. Dispute Resolution</Typography>
                <Typography paragraph>
                    Binding Arbitration at the option of the party seeking relief, any dispute under these Terms may be settled by binding arbitration in accordance with the rules of the Indian Arbitration Association.
                </Typography>

                <Typography variant="h4" gutterBottom>9. Contact Me</Typography>
                <Typography paragraph>
                    In order to resolve a complaint regarding the Services or to receive further information regarding use of the Services, please contact me at:
                </Typography>
                <Typography>
                    https://mediautils.app/contact-us
                </Typography>
            </Box>
        </Container>
    );
};

export default TermsOfService;
