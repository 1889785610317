import React from 'react';
import { Box, Typography, Link, Container } from '@mui/material';

const Footer = () => {
    return (
        <Box
            sx={{
                width: '100%',
                backgroundColor: '#282c34',
                color: 'white',
                py: 2,
                mt: 1,
                textAlign: 'center',
                position: 'relative',
                bottom: 0,
            }}
        >
            <Container maxWidth="lg">
                <Typography variant="body1">
                    &copy; {new Date().getFullYear()} Media Utils. All Rights Reserved.
                </Typography>
                <Typography variant="body2" sx={{ mt: 1 }}>
                    <Link href="/privacy-policy" color="inherit" sx={{ textDecoration: 'none', mr: 2 }}>
                        Privacy Policy
                    </Link>
                    <Link href="/terms-of-service" color="inherit" sx={{ textDecoration: 'none', mr: 2 }}>
                        Terms of Service
                    </Link>
                    <Link href="/contact-us" color="inherit" sx={{ textDecoration: 'none' }}>
                        Contact Us
                    </Link>
                </Typography>
            </Container>
        </Box>
    );
};

export default Footer;
