import React from 'react';
import { Typography, Box, CircularProgress } from '@mui/material';

const LoadingScreen = (props) => {
    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', mt: 4 }}>
            <CircularProgress />
            <Typography mt={2}>{props.textData} images...</Typography>
        </Box>
    )
}

export default LoadingScreen;