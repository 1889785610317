import React, { useState } from 'react';
import { Box, Typography, Button } from '@mui/material';
import { Helmet } from 'react-helmet';
import apiService from '../services/apiService';
import LoadingScreen from './LoadingScreen';
import CompressionOutputPreview from './CompressionOutputPreview';
import FileSelector from './helper/FileSelector';
import FilePreviewGrid from './FilePreviewGrid';

const Compress = () => {
    const [files, setFiles] = useState([]);
    const [output, setOutput] = useState([]);
    const [loading, setLoading] = useState(false);
    const allowedFileType = ".jpg, .jpeg, .png, .gif, .webp";
    const handleFileChange = (e) => {
        setFiles([...files, ...Array.from(e.target.files)]);
    };

    const handleRemoveFile = (index) => {
        setFiles(files.filter((_, i) => i !== index));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);

        const formData = new FormData();
        files.forEach(file => formData.append('images', file));

        try {
            const response = await apiService.compressImage(formData);
            if (response.status === 200) {
                console.log(response.data);
                setOutput(response.data);
            }
        } catch (error) {
            console.error('Error processing the images', error);
        } finally {
            setLoading(false);
        }
    };

    return (
        <Box sx={{ textAlign: 'center', mt: 4 }}>
            <Helmet>
                <title>Compress Images - Free Online Image Compression | Media Utils</title>
                <meta name="description" content="Compress your images online for free. Reduce file size without losing quality. Perfect for web optimization and faster loading times." />
            </Helmet>
            {loading ? (
                <LoadingScreen textData="Compressing" />
            ) : (
                <>
                    {output.length === 0 ? (
                        <>
                            <Typography variant="h1" component="h1" gutterBottom sx={{ fontSize: '2.5rem', fontWeight: 'bold' }}>Compress Images</Typography>
                            <Typography variant="h6" gutterBottom>Quickly reduce the file size of your JPG, PNG, SVG, and GIF images without sacrificing quality.

                            </Typography>
                            <Typography variant="h6" gutterBottom>Enhance your workflow and free up storage by compressing your images with our reliable and easy-to-use service.

                            </Typography>


                            <form onSubmit={handleSubmit} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                <FileSelector filesLength={files.length} handleFileChange={handleFileChange} allowedFileType={allowedFileType}></FileSelector>
                                <FilePreviewGrid files={files} handleRemoveFile={handleRemoveFile} />
                                {files.length > 0 ? (
                                    <Button type="submit" variant="contained" color="primary">Submit</Button>

                                ) : (<></>)}
                            </form>
                        </>
                    ) : (
                        <>
                            <Typography variant="h4" gutterBottom>Your Images are compressed!</Typography>
                            <CompressionOutputPreview output={output} />
                        </>
                    )}
                </>
            )}
        </Box>
    );
};

export default Compress;