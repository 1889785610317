import Axios from 'axios';
import { API_URL } from '../constants/global'

const apiService = {
    compressImage: (formData) => Axios.post(`${API_URL}images/compress`, formData, {
        headers: {
            'Content-Type': 'multipart/form-data',
        },
    }),
    convertImage: (formData) => Axios.post(`${API_URL}images/convert`, formData, {
        headers: {
            'Content-Type': 'multipart/form-data',
        },
    }),
    cropImage: (formData) => Axios.post(`${API_URL}images/crop`, formData, {
        headers: {
            'Content-Type': 'multipart/form-data',
        },
    }),
    rotateImage: (formData) => Axios.post(`${API_URL}images/rotate`, formData, {
        headers: {
            'Content-Type': 'multipart/form-data',
        },
    }),
    addWatermark: (formData) => Axios.post(`${API_URL}images/watermark`, formData, {
        headers: {
            'Content-Type': 'multipart/form-data',
        },
    }),
    removeBackground: (formData) => Axios.post(`${API_URL}images/remove-background`, formData, {
        headers: {
            'Content-Type': 'multipart/form-data',
        },
    }),
    contactUs: (formData) => Axios.post(`${API_URL}general/contact-us`, formData, {
        headers: {
            'Content-Type': 'application/json',
        },
    }),
};

export default apiService;