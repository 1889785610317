import React from 'react';
import { Grid, Card, CardMedia, CardContent, Typography, IconButton } from '@mui/material';
import { Close } from '@mui/icons-material';

const FilePreviewGrid = ({ files, handleRemoveFile }) => {
    return (
        <>
            {files.length > 0 && (
                <Grid container spacing={2} sx={{ mb: 2 }}>
                    {files.map((file, index) => (
                        <Grid item key={index}>
                            <Card sx={{ maxHeight: 170, minHeight: 170, minWidth: 150, maxWidth: 150, position: 'relative' }}>
                                <CardMedia
                                    component="img"
                                    height="100"
                                    image={URL.createObjectURL(file)}
                                    alt={`preview ${index}`}
                                />
                                <CardContent>
                                    <Typography variant="body2" color="textSecondary" style={{ textOverflow: "" }}>
                                        {file.name.slice(0, 20) + (file.name.length > 20 ? "..." : "")}
                                    </Typography>
                                </CardContent>
                                <IconButton
                                    size="small"
                                    sx={{ position: 'absolute', top: 0, right: 0, background: "white" }}
                                    onClick={() => handleRemoveFile(index)}
                                >
                                    <Close />
                                </IconButton>
                            </Card>
                        </Grid>
                    ))}
                </Grid>
            )}
        </>
    );
};

export default FilePreviewGrid;
