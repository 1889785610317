import React, { useEffect, useState } from 'react';
import { Button, Typography, Box, Grid } from '@mui/material';
import { Helmet } from 'react-helmet';
import LoadingScreen from './LoadingScreen';
import ReactCrop from 'react-image-crop';
import apiService from '../services/apiService';
import DownloadButton from './helper/DownloadButton'
import { API_URL } from "../constants/global";
import './ImageStyle.css';
import 'react-image-crop/dist/ReactCrop.css';

const Crop = () => {
    const [imageSrc, setImageSrc] = useState(null);
    const [loading, setLoading] = useState(false);
    const [crop, setCrop] = useState({ unit: '%', width: 50, height: 50, x: 5, y: 5 });
    const [completedCrop, setCompletedCrop] = useState(null);
    const [image, setImage] = useState(null);
    const [output, setOutput] = useState(false);
    const [filename, setFilename] = useState('');

    useEffect(() => {
    }, [crop, imageSrc, image, filename])

    function base64ToFile(dataURI, filename) {
        // Split the base64 string in data and contentType
        const b64Parts = dataURI.split(',');
        const b64Refined = b64Parts[1];
        const byteCharacters = atob(b64Refined);
        const byteNumbers = new Array(byteCharacters.length);

        for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
        }

        const byteArray = new Uint8Array(byteNumbers);

        // Create a blob from the byte array
        const blob = new Blob([byteArray], { type: b64Parts[0].split(':')[1].split(';')[0] });

        // Create a file from the blob
        const file = new File([blob], filename, { type: blob.type });

        return file;
    }

    const handleFileChange = async (event) => {
        const file = event.target.files[0];

        setImage(file);
        setFilename(event.target.files[0].name);
        if (file) {
            const imageDataUrl = await readFile(file);


            setCrop(currentCrop => ({ ...currentCrop, width: 50, height: 50, x: 50, y: 50 }));

            setImageSrc(imageDataUrl);
            setCompletedCrop(crop);
        }
    };

    const handleCrop = async () => {

        if (!crop || !image) {
            return;
        }

        setLoading(true);

        try {
            const formData = new FormData();
            let imagelocal = base64ToFile(imageSrc, filename);
            formData.append('image', imagelocal);
            formData.append('width', crop.width);
            formData.append('height', crop.height);
            formData.append('x', crop.x);
            formData.append('y', crop.y);

            const response = await apiService.cropImage(formData);
            if (response.status === 200) {
                setOutput(response.data.croppedImage);
            } else {
                throw new Error(JSON.stringify({ status: response.status, body: await response.text() }))
            }
        } catch (error) {
            console.error('Error processing the image', error);
        } finally {
            setLoading(false);
        }
    };

    const readFile = (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = () => resolve(reader.result);
            reader.onerror = error => reject(error);
            reader.readAsDataURL(file);
        });
    };

    return (
        <Box sx={{ textAlign: 'center', mt: 4 }}>
            <Helmet>
                <title>Crop Images - Free Online Image Cropping Tool | Media Utils</title>
                <meta name="description" content="Customize the visible areas of your images with our easy-to-use cropping tool. Perfect for refining image dimensions and enhancing composition." />
            </Helmet>

            {loading ? (
                <LoadingScreen textData="Cropping" />
            ) : (
                <>
                    <Typography variant="h1" component="h1" gutterBottom sx={{ fontSize: '2.5rem', fontWeight: 'bold' }}>Crop Image</Typography>
                    <Typography variant="h6" gutterBottom>Quickly adjust the dimensions of your images to perfect your content.</Typography>
                    <Typography variant="h6" gutterBottom>Our easy-to-use cropping tool allows you to select and modify the visible areas of your images, ensuring they fit exactly as you need.</Typography>
                    {!output ? (
                        <>
                            {
                                imageSrc && (
                                    <ReactCrop
                                        crop={crop}
                                        onChange={(crop, percentageCrop) => {
                                            setCrop(percentageCrop);
                                        }}
                                        onComplete={setCompletedCrop}
                                    >
                                        <img src={imageSrc} style={{ width: image.width / 2, height: image.height / 2 }} alt="To crop" />
                                    </ReactCrop>
                                )
                            }

                            <Grid container spacing={2} justifyContent="center">
                                <Grid item>
                                    <Button variant="contained" component="label" disabled={image}>
                                        Select Image
                                        <input type="file" hidden accept="image/*" onChange={handleFileChange} />
                                    </Button>
                                </Grid>
                                <Grid item>
                                    <Button variant="contained" color="primary" onClick={handleCrop} disabled={!completedCrop?.width || !completedCrop?.height}>
                                        Crop Image
                                    </Button>
                                </Grid>
                            </Grid>
                        </>
                    ) : (
                        <Grid spacing={2} justifyContent="center">
                            <Grid item>
                                <Typography variant="h5" gutterBottom>Output</Typography>
                                <img src={API_URL + output[0]["imagePath"]} alt="Cropped"
                                    className="responsive-image"
                                    style={{
                                        width: image.width * crop.width / 100,
                                        height: image.height * crop.height / 100,
                                        objectFit: 'contain'
                                    }} />
                            </Grid>
                            <Grid item>
                                <DownloadButton output={output} operation={"Cropped"} />
                            </Grid>
                        </Grid>

                    )}

                </>)
            }
        </Box >
    );
};

export default Crop;
