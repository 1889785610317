import React, { useState } from 'react';
import { Button, TextField, Typography, Box, MenuItem } from '@mui/material';
import { Helmet } from 'react-helmet';
import apiService from '../services/apiService';
import LoadingScreen from './LoadingScreen';
import FileSelector from './helper/FileSelector';
import FilePreviewGrid from './FilePreviewGrid';
import DownloadButton from './helper/DownloadButton'

const Convert = () => {
    const [files, setFiles] = useState([]);
    const [loading, setLoading] = useState(false);
    const [format, setFormat] = useState('png');
    const [output, setOutput] = useState('');
    const allowedFileType = ".jpg, .jpeg, .png, .gif, .webp";

    const handleFileChange = (e) => {
        setFiles([...files, ...Array.from(e.target.files)]);
    };

    const handleRemoveFile = (index) => {
        setFiles(files.filter((_, i) => i !== index));
    };

    const handleFormatChange = (e) => {
        setFormat(e.target.value);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);

        const formData = new FormData();
        files.forEach(file => formData.append('images', file));
        formData.append('format', format);

        try {
            const response = await apiService.convertImage(formData);
            if (response.status === 200) {
                setOutput(response.data.convertedImages);
            }
            else {
                throw new Error(JSON.stringify({ status: response.status, body: await response.text() }))
            }
        } catch (error) {
            console.error('Error processing the image', error);
        } finally {
            setLoading(false);
        }
    };

    return (
        <Box sx={{ textAlign: 'center', mt: 4 }}>
            <Helmet>
                <title>Convert Images - Free Online Image Conversion | Media Utils</title>
                <meta name="description" content="Seamlessly convert your images between JPG, PNG, WEBP, GIF, and more. Maintain image quality with our fast and user-friendly conversion tool." />
            </Helmet>
            {loading ? (
                <LoadingScreen textData="Converting" />
            ) : (
                <>
                    {output.length === 0 ? (<>
                        <Typography variant="h1" component="h1" gutterBottom sx={{ fontSize: '2.5rem', fontWeight: 'bold' }}>Convert Image</Typography>
                        <Typography variant="h6" gutterBottom>Easily convert your images across multiple formats, including JPG, PNG, WEBP, and GIF.</Typography>
                        <Typography variant="h6" gutterBottom>Ensure optimal compatibility and quality with our fast and user-friendly tool.</Typography>
                        <form onSubmit={handleSubmit} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                            <FileSelector filesLength={files.length} handleFileChange={handleFileChange} allowedFileType={allowedFileType}></FileSelector>
                            <FilePreviewGrid files={files} handleRemoveFile={handleRemoveFile} />
                            <TextField
                                select
                                label="Format"
                                value={format}
                                onChange={handleFormatChange}
                                sx={{ mb: 2 }}
                            >
                                <MenuItem value="png">PNG</MenuItem>
                                <MenuItem value="jpg">JPG</MenuItem>
                                <MenuItem value="gif">GIF</MenuItem>
                                <MenuItem value="tiff">TIFF</MenuItem>
                            </TextField>
                            {files.length > 0 ? (
                                <Button type="submit" variant="contained" color="primary">Submit</Button>

                            ) : (<></>)}
                        </form>
                    </>) : (<>
                        <Box sx={{ mt: 4 }}>
                            <Typography variant="h5" gutterBottom>Your Images are Converted!</Typography>
                            <DownloadButton output={output} operation={"Converted"} />
                        </Box>
                    </>)
                    }

                </>)
            }
        </Box >
    );
};

export default Convert;
